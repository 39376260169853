import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import Header from '../components/Header'
import Footer from '../components/Footer'

export default function FormSubmitSuccess() {

    const [mobileWidth, setMobileWidth] = useState(537.196);
    const [mobileHeight, setMobileHeight] = useState(292.1155);

    useEffect(() => {
        const toggleDimensions = () => {
            if (window.innerWidth <= 768) {
                setMobileWidth(268.598);
                setMobileHeight(146.05775);
            }
        }

        toggleDimensions();

        window.addEventListener('resize', toggleDimensions);

        return () => {
            window.removeEventListener('resize', toggleDimensions);
        }
    }, [])

    return (
        <div className='bg-background min-h-screen'>
            <Header />
            <div className='pt-14 lg:pt-16 lg:pb-24 px-6 md:px-8 lg:px-12 xl:px-20 2xl:px-36 max-w-screen-2xl w-full mx-auto flex flex-col justify-center'>
                <div className='flex flex-col md:flex-row'>
                    <div className='w-1/2 md:mr-5'>
                        <svg id="b3c5850d-3d23-4aad-a12c-b5e8440d4fde" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={`${mobileWidth}`} height={`${mobileHeight}`} viewBox="0 0 924 458.12749"><ellipse cx="448.17846" cy="584.7146" rx="21.53369" ry="6.76007" transform="translate(-396.01217 573.65899) rotate(-69.08217)" fill="#2f2e41"/><circle cx="408.37125" cy="617.2367" r="43.06735" transform="translate(-404.30923 700.52813) rotate(-80.78252)" fill="#2f2e41"/><rect x="250.74566" y="430.10005" width="13.08374" height="23.44171" fill="#2f2e41"/><rect x="276.91314" y="430.10005" width="13.08374" height="23.44171" fill="#2f2e41"/><ellipse cx="261.6488" cy="453.81434" rx="10.90314" ry="4.08868" fill="#2f2e41"/><ellipse cx="287.81628" cy="453.26918" rx="10.90314" ry="4.08868" fill="#2f2e41"/><circle cx="409.4616" cy="606.33348" r="14.71922" transform="translate(-155.63358 -208.64722) rotate(-1.68323)" fill="#fff"/><circle cx="271.4616" cy="385.39723" r="4.90643" fill="#3f3d56"/><path d="M366.59454,577.1852c-3.47748-15.57379,7.63867-31.31043,24.82861-35.1488s33.94423,5.67511,37.42171,21.24884-7.91492,21.31762-25.10486,25.156S370.072,592.75905,366.59454,577.1852Z" transform="translate(-138 -220.93625)" fill="#e6e6e6"/><ellipse cx="359.86311" cy="597.25319" rx="6.76007" ry="21.53369" transform="translate(-471.98369 445.52283) rotate(-64.62574)" fill="#2f2e41"/>
                            <path d="M387.21673,632.77358c0,4.21515,10.85327,12.53857,22.89656,12.53857s23.33515-11.867,23.33515-16.08209-11.29193.81775-23.33515.81775S387.21673,628.55843,387.21673,632.77358Z" transform="translate(-138 -220.93625)" fill="#fff"/><path d="M711.25977,500.2,664.48,546.37,558.75977,650.69l-2.13965,2.11L544.7002,664.56,519.71,639.87l-2.20019-2.17-45.69-45.13h-.00976L457.16992,578.1l-8.6499-8.55-25.76025-25.44-3.4795-3.44-41.06006-40.56a117.65792,117.65792,0,0,1-20.52-27.63c-.5-.91-.97022-1.83-1.43018-2.75A117.50682,117.50682,0,0,1,480.98,301.2h.01025c.37989.06.75.12,1.12989.2a113.60526,113.60526,0,0,1,11.91015,2.77A117.09292,117.09292,0,0,1,523.1499,317.1q1.4253.885,2.82031,1.8a118.17183,118.17183,0,0,1,18.46973,15.09l.3501-.35.3501.35a118.54248,118.54248,0,0,1,10.83007-9.58c.82959-.65,1.66993-1.29,2.50977-1.91a117.44922,117.44922,0,0,1,90.51025-21.06,111.92113,111.92113,0,0,1,11.91993,2.78q1.96507.55509,3.8999,1.2c1.04.34,2.08008.69,3.10986,1.07a116.42525,116.42525,0,0,1,24.39014,12.1q2.50488,1.63494,4.93994,3.42A117.54672,117.54672,0,0,1,711.25977,500.2Z" transform="translate(-138 -220.93625)" fill="#374785"/><path d="M664.48,546.37,558.75977,650.69l-2.13965,2.11L544.7002,664.56,519.71,639.87l-2.20019-2.17-45.69-45.13c7.34034-1.71,18.62012.64,22.75,2.68,9.79,4.83,17.84034,12.76,27.78028,17.28A46.138,46.138,0,0,0,550.68018,615.66c17.81982-3.74,31.60986-17.52,43.77-31.08,12.15966-13.57,24.58984-28.13,41.67968-34.42C645.14014,546.84,654.81982,546.09,664.48,546.37Z" transform="translate(-138 -220.93625)" opacity="0.15"/>
                            <path d="M741.33984,335.92a118.15747,118.15747,0,0,0-52.52978-30.55c-1.31983-.37-2.62988-.7-3.96-1.01A116.83094,116.83094,0,0,0,667.46,301.57c-1.02-.1-2.04-.17-3.06982-.22a115.15486,115.15486,0,0,0-15.43018.06,118.39675,118.39675,0,0,0-74.83984,33.45l-.36035-.36-.35987.36a118.61442,118.61442,0,0,0-46.6997-28.08c-.99024-.32-1.99024-.63-2.99024-.92a119.67335,119.67335,0,0,0-41.62012-4.45c-.38964.02-.77978.05-1.15966.09a118.30611,118.30611,0,0,0-69.39991,29.4c-1.82031,1.6-3.61035,3.28-5.35009,5.02A119.14261,119.14261,0,0,0,379.54,463.47c.3501.94.73,1.87006,1.12988,2.8a118.153,118.153,0,0,0,25.51026,37.95l38.91992,38.42,3.06006,3.03,84.21972,83.13,2.16992,2.15,22.12012,21.84,17.08985,16.87L741.33984,504.21A119.129,119.129,0,0,0,741.33984,335.92ZM739.23,502.08,573.75977,665.44l-14.94971-14.76-21.6499-21.37-2.16993-2.14-82.58007-81.53-3.01026-2.97L408.2998,502.09A115.19343,115.19343,0,0,1,383.54,465.37c-.3999-.93-.78027-1.86-1.12988-2.79A116.13377,116.13377,0,0,1,408.2998,338.04q2.79054-2.79,5.71-5.34H414.02a115.38082,115.38082,0,0,1,66.48-28.16q4.905-.42,9.81982-.42c1.23,0,2.4502.02,3.68018.06a116.0993,116.0993,0,0,1,29.6499,4.8c.99024.29,1.98.6,2.96.93a114.15644,114.15644,0,0,1,29.33008,14.49,115.61419,115.61419,0,0,1,16.41016,13.64l1.06006,1.06.34961-.35.35009.35,1.06006-1.06a115.674,115.674,0,0,1,85.71-33.86c1.27.04,2.54.1,3.81006.19,1.02.06,2.04.13,3.05029.23a115.12349,115.12349,0,0,1,19.08985,3.35c1.33984.34,2.66992.71,3.98974,1.12A115.9591,115.9591,0,0,1,739.23,502.08Z" transform="translate(-138 -220.93625)" fill="#3f3d56"/>
                            <path d="M506.87988,308.71c-6.41992,5.07-13.31006,9.75-17.48,16.68-3.06982,5.12-4.3999,11.07-5.39013,16.95-1.91993,11.44-2.73975,23.16-6.5,34.12994-3.75,10.97-11.06983,21.45-21.91993,25.54-6.73,2.53-14.1499,2.39-21.31982,1.9-17.68994-1.2-35.5-4.37-51.41992-12.16-8.8999-4.36-17.53028-10.24-27.41992-10.89a25.39538,25.39538,0,0,0-6.02.33A117.494,117.494,0,0,1,480.98,301.2h.01025c.37989.06.75.12,1.12989.2a113.60526,113.60526,0,0,1,11.91015,2.77A117.48205,117.48205,0,0,1,506.87988,308.71Z" transform="translate(-138 -220.93625)" opacity="0.15"/><path d="M224.76412,625.76982a28.74835,28.74835,0,0,0,27.7608-4.89018c9.72337-8.16107,12.77191-21.60637,15.25242-34.056L275.11419,550l-15.36046,10.57663c-11.04633,7.60609-22.34151,15.45585-29.99,26.47289s-10.987,26.0563-4.8417,37.97726Z" transform="translate(-138 -220.93625)" fill="#e6e6e6"/><path d="M226.07713,670.35248c-1.55468-11.32437-3.15331-22.7942-2.06278-34.24.96851-10.16505,4.06971-20.09347,10.38337-28.23408a46.968,46.968,0,0,1,12.0503-10.9196c1.205-.76061,2.31413,1.14911,1.11434,1.90641a44.6513,44.6513,0,0,0-17.66194,21.31042c-3.84525,9.78036-4.46274,20.44179-3.80011,30.83136.40072,6.283,1.25,12.52474,2.1058,18.75851a1.14389,1.14389,0,0,1-.771,1.358,1.11066,1.11066,0,0,1-1.358-.771Z" transform="translate(-138 -220.93625)" fill="#f2f2f2"/><path d="M241.05156,650.3137a21.16242,21.16242,0,0,0,18.439,9.51679c9.33414-.4431,17.11583-6.95774,24.12082-13.14262l20.71936-18.29363L290.618,627.738c-9.86142-.47193-19.97725-.91214-29.36992,2.12894s-18.05507,10.35987-19.77258,20.082Z" transform="translate(-138 -220.93625)" fill="#e6e6e6"/>
                            <path d="M221.68349,676.86232c7.48292-13.24055,16.16246-27.95592,31.67134-32.65919a35.34188,35.34188,0,0,1,13.32146-1.37546c1.41435.12195,1.06117,2.30212-.3506,2.18039a32.83346,32.83346,0,0,0-21.259,5.62435c-5.99423,4.0801-10.66138,9.75253-14.61162,15.76788-2.41964,3.68458-4.587,7.52548-6.75478,11.36122-.69277,1.22582-2.7177.341-2.01683-.89919Z" transform="translate(-138 -220.93625)" fill="#f2f2f2"/><circle cx="300.09051" cy="76.05079" r="43.06733" fill="#2f2e41"/><rect x="280.4649" y="109.85048" width="13.08374" height="23.44171" fill="#2f2e41"/><rect x="306.63238" y="109.85048" width="13.08374" height="23.44171" fill="#2f2e41"/><ellipse cx="291.36798" cy="133.56477" rx="10.90314" ry="4.08868" fill="#2f2e41"/><ellipse cx="317.53552" cy="133.0196" rx="10.90314" ry="4.08868" fill="#2f2e41"/><circle cx="301.18084" cy="65.14766" r="14.71923" fill="#fff"/><ellipse cx="444.18084" cy="289.08391" rx="4.88594" ry="4.92055" transform="translate(-212.34041 177.70056) rotate(-44.98705)" fill="#3f3d56"/>
                            <path d="M396.31372,256.93569c-3.47748-15.57379,7.63865-31.31043,24.82866-35.14881s33.94421,5.67511,37.42169,21.24891-7.91492,21.31768-25.10486,25.156S399.79126,272.50954,396.31372,256.93569Z" transform="translate(-138 -220.93625)" fill="#e6e6e6"/><ellipse cx="770.70947" cy="573.81404" rx="6.76007" ry="21.53369" transform="translate(-326.96946 400.5432) rotate(-39.51212)" fill="#2f2e41"/><circle cx="808.20127" cy="616.79758" r="43.06735" transform="translate(-226.36415 -83.51221) rotate(-9.21748)" fill="#2f2e41"/><rect x="676.74319" y="429.66089" width="13.08374" height="23.44171" fill="#2f2e41"/><rect x="650.5757" y="429.66089" width="13.08374" height="23.44171" fill="#2f2e41"/><ellipse cx="678.92379" cy="453.37519" rx="10.90314" ry="4.08868" fill="#2f2e41"/><ellipse cx="652.75631" cy="452.83005" rx="10.90314" ry="4.08868" fill="#2f2e41"/><path d="M823.27982,593.8192c-13.57764-11.21939-21.12423-21.50665-10.95965-33.80776s29.41145-13.178,42.98908-1.9586,16.34444,30.28655,6.17986,42.58766S836.85746,605.03859,823.27982,593.8192Z" transform="translate(-138 -220.93625)" fill="#374785"/><circle cx="793.31102" cy="594.44957" r="14.71922" transform="translate(-155.11887 -197.37727) rotate(-1.68323)" fill="#fff"/><circle cx="650.1378" cy="369.86765" r="4.90643" fill="#3f3d56"/>
                            <path d="M771.06281,606.5725c-2.98056,2.98056-5.08788,12.64434-.89538,16.83684s16.51464-.26783,19.49516-3.24834-4.50917-3.35271-8.70164-7.54518S774.04337,603.59194,771.06281,606.5725Z" transform="translate(-138 -220.93625)" fill="#fff"/><ellipse cx="841.39416" cy="654.27547" rx="6.76007" ry="21.53369" transform="translate(-316.14156 122.58618) rotate(-20.9178)" fill="#2f2e41"/><path d="M1061,679.06375H139a1,1,0,0,1,0-2h922a1,1,0,0,1,0,2Z" transform="translate(-138 -220.93625)" fill="#ccc"/>
                        </svg>
                    </div>
                    <div className='flex flex-col justify-center'>
                        <div className='text-white font-mono text-4xl lg:text-5xl pt-10 font-semibold'>Form successfully submitted!</div>
                        <div className='text-white font-mono text-xs lg:text-sm pt-2'>Thank you for reaching out, I'll try to get back to you as soon as possible.</div>
                        <Link to='/' className='mt-4 hidden md:block'>
                            <button className='px-2 py-1 hover:bg-hover-bg border border-border rounded-md text-white outline-none'>
                                <div className='flex flex-col'>
                                    <div className='flex flex-row justify-center items-center'>
                                        <div className='text-white text-opacity-80 text-sm font-mono'>Return to home</div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-arrow-right-short" viewBox="0 0 16 16" className='ml-1.5'>
                                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                        </svg>
                                    </div>
                                </div>
                            </button>
                        </Link>
                    </div>
                </div>
                <Link to='/' className='mt-4 md:hidden'>
                    <button className='px-2 py-1 hover:bg-hover-bg border border-border rounded-md text-white outline-none'>
                        <div className='flex flex-col'>
                            <div className='flex flex-row justify-center items-center'>
                                <div className='text-white text-opacity-80 text-sm font-mono'>Return to home</div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" class="bi bi-arrow-right-short" viewBox="0 0 16 16" className='ml-1.5'>
                                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
                                </svg>
                            </div>
                        </div>
                    </button>
                </Link>
            </div>
            <Footer isPage={true} isContact={true} />
        </div>
    );
}